<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-1M"
            defaultEnd="7d"
            label="작업일"
            name="workDts"
            v-model="searchParam.workDts"
          />
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" @click="openDetail(props.row)">
              <q-card class="mobileTableCardLayer">
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.plantName }} 
                  </div>
                  <div class="text-grid-etc txt-box-grid text-gray-box">
                    정비오더 : {{ props.row.workResultName }} 
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.workDt }} {{ props.row.workResultWorkName }}
                  </div>
                </q-card-section>
                <q-card-section class="grid/7-card-etc">
                  <div class="text-grid-etc">
                    <span :class="[getColorStatus(props.row.workOprTypeCd)]">{{ props.row.workOprTypeName }} - {{ props.row.workOprTypeCd=='WOTC000001' ? props.row.workDeptName : props.row.vendorName }}</span> 
                    <span :class="[getColors(props.row.workEndFlag)]">{{ props.row.workEndFlagName }}</span>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  data() {
    return {
      searchParam: {
        plantCd: null,
        workDts: [],
        workMonths: [],
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'workResultId' },
          { index: 1, colName: 'workResultId' },
          { index: 2, colName: 'workResultWorkId' },
          { index: 3, colName: 'workResultWorkId' },
        ],
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'workResultName',
            field: 'workResultName',
            label: '정비오더명',
            align: 'left',
            sortable: true,
            style: 'width:200px',
            type: 'link'
          },
          {
            fix: true,
            name: 'workOprTypeName',
            field: 'workOprTypeName',
            label: '작업제어구분',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'workResultWorkName',
            field: 'workResultWorkName',
            label: '작업명',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            fix: true,
            name: 'workDt',
            field: 'workDt',
            label: '작업일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'workEndFlag',
            field: 'workEndFlag',
            label: '작업결과',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'workContent',
            field: 'workContent',
            label: '작업내역',
            align: 'left',
            sortable: true,
          },
          {
            name: 'workpermitTypesName',
            field: 'workpermitTypesName',
            label: '작업허가서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'workRiskFactorsName',
            field: 'workRiskFactorsName',
            label: '위험요인',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '협력업체',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      copyUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.wod.workorder.daily.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '정비오더 상세';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        workPlanId: row ? row.workPlanId : '',
        plantCd: row ? row.plantCd : '',
        woWorkPlanStepCd: row ? row.woWorkPlanStepCd : 'WWPSC00001',
        woRequestId: row ? row.woRequestId : '',
      };
      this.popupOptions.target = () => import(`${'./workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WDRC000010': // 작업완료
          returnColor = 'txt-box-grid text-blue-box';
          break;
        case 'WDRC000009': // 작업취소
          returnColor = 'txt-box-grid text-red-box';
          break;
        case 'WDRC000001': // 작업결과입력
          returnColor = 'txt-box-grid text-orange-box';
          break;
        case 'WDRC000002': // 작업결과입력
          returnColor = 'txt-box-grid text-green-box';
          break;
      }

      return returnColor;
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'WOTC000001': // 자체작업
          cls = 'txt-box-grid text-positive-box';
          break;
        case 'WOTC000002': // 외주작업
          cls = 'txt-box-grid text-accent-box';
          break;
        case 'WOTC000003': // 단가작업
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
    openDetail(row) {
      this.popupOptions.title = '일일작업 결과';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        workResultWorkId: row ? row.workResultWorkId : '',
        workDt: row ? row.workDt : '',
        workResultDailyId: row ? row.workResultDailyId : '',
      };
      this.popupOptions.target = () => import(`${'./workOrderDailyPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    }
  }
};
</script>
