var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    defaultStart: "-1M",
                    defaultEnd: "7d",
                    label: "작업일",
                    name: "workDts",
                  },
                  model: {
                    value: _vm.searchParam.workDts,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "workDts", $$v)
                    },
                    expression: "searchParam.workDts",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "card cardcontents" }, [
        _c(
          "div",
          { staticClass: "card-body qtable-card-body" },
          [
            _c("q-table", {
              staticClass: "qtable-card",
              attrs: {
                grid: "",
                title: "",
                data: _vm.grid.data,
                columns: _vm.grid.columns,
                "hide-header": "",
                "hide-bottom": "",
                "rows-per-page-options": [0],
                "virtual-scroll": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "top-right",
                  fn: function () {
                    return undefined
                  },
                  proxy: true,
                },
                {
                  key: "item",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                          on: {
                            click: function ($event) {
                              return _vm.openDetail(props.row)
                            },
                          },
                        },
                        [
                          _c(
                            "q-card",
                            { staticClass: "mobileTableCardLayer" },
                            [
                              _c(
                                "q-card-section",
                                { staticClass: "grid-card-etc" },
                                [
                                  _c("div", { staticClass: "text-grid-etc" }, [
                                    _vm._v(
                                      " " + _vm._s(props.row.plantName) + " "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-grid-etc txt-box-grid text-gray-box",
                                    },
                                    [
                                      _vm._v(
                                        " 정비오더 : " +
                                          _vm._s(props.row.workResultName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("q-card-section", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-grid-title q-mt-sm q-mb-xs",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(props.row.workDt) +
                                        " " +
                                        _vm._s(props.row.workResultWorkName) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "q-card-section",
                                { staticClass: "grid/7-card-etc" },
                                [
                                  _c("div", { staticClass: "text-grid-etc" }, [
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          _vm.getColorStatus(
                                            props.row.workOprTypeCd
                                          ),
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(props.row.workOprTypeName) +
                                            " - " +
                                            _vm._s(
                                              props.row.workOprTypeCd ==
                                                "WOTC000001"
                                                ? props.row.workDeptName
                                                : props.row.vendorName
                                            )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          _vm.getColors(props.row.workEndFlag),
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(props.row.workEndFlagName)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm.grid.data.length === 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                  },
                  [
                    _c(
                      "q-card",
                      { staticClass: "mobileTableCardLayer" },
                      [
                        _c(
                          "q-card-section",
                          { staticClass: "text-center" },
                          [
                            _c("q-card-section", { staticClass: "q-pt-xs" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-grid-title q-mt-sm q-mb-xs",
                                },
                                [_vm._v("데이터가 존재하지 않습니다.")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }